.QuickBoardMenu-menu {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background-color: #282c34;
	color: white;
  }
  
  .QuickBoardMenu-button-toggle {
	position: fixed;
	top: 20px;
	right: 20px;
	z-index: 1001;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	width: 100px;
	height: 50px;
	font-size: 20px;
	font-weight: bold;
	border: 2px solid #555;
  }
  
  .QuickBoardMenu-navigation {
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	max-width: 300px;
	width: 0;
	background-color: #333;
	color: white;
	overflow-x: hidden;
	transition: width 0.3s ease;
	z-index: 1000;
  }
  
  .QuickBoardMenu-navigation-open {
	width: 300px;
  }
  
  .QuickBoardMenu-buttonGroup {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 10px;
  }
  
  .QuickBoardMenu-button {
	width: 100%;
	height: 50px;
	font-size: 18px;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #444;
	color: white;
	border: 2px solid #555;
	margin-bottom: 10px;
  }
  
  .QuickBoardMenu-button:hover {
	background-color: #555;
	border-color: #666;
  }
  
  .QuickBoardMenu-languagePicker,
  .QuickBoardMenu-darkModeToggle {
	width: 100%;
	height: 50px;
	background-color: #444;
	color: white;
	border: 2px solid #555;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 40px;
	margin-bottom: 10px;
  }
  
  .QuickBoardMenu-languagePicker:hover,
  .QuickBoardMenu-darkModeToggle:hover {
	background-color: #555;
	border-color: #666;
  }
  