:root {
	--background-color: #1e1e1e00;
	--text-color-modal: #1e1e1e;
	--text-color-dropzone: #1e1e1e;
	--border-color: #444;
	--primary-color: #007bff;
	--primary-hover-color: #0056b3;
	--dropzone-bg: hsla(0, 0%, 53%, 0.192);
	--dropzone-border-radius: 15px;
	--file-preview-bg: #2a2a2a;
	--file-preview-hover-bg: #3a3a3a;
}

.modal {
	background-color: transparent !important;
	color: var(--text-color-modal);
}

.FileUploadDialogBody {
	display: flex;
	flex-direction: column;
	row-gap: 10px;
}

.capturedFilesContainer {
	max-height: 200px;
	height: auto;
	overflow-x: auto;
	overflow-y: hidden;
	flex-direction: row;
	white-space: nowrap;
}

.fileContainer {
	position: relative;
	padding: 10px;
	width: 200px;
	display: inline-block;
	overflow-y: hidden;
}

.fileContainer img, .filePreview {
	width: 100%;
	height: 200px;
	object-fit: contain;
	background-color: var(--file-preview-bg);
	border-radius: 8px;
}

.filePreview {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	color: var(--text-color-dropzone);
}

.fileContainer img:hover, .filePreview:hover {
	filter: brightness(0.8);
}

.fileOverlay {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 30px;
	height: 30px;
	background-color: rgba(255, 0, 0, 0.7);
	display: none;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	pointer-events: none;
}

.fileContainer:hover .fileOverlay {
	display: flex;
}

.dropzone {
	display: flex;
	height: 100px;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	vertical-align: middle;
	text-align: center;
	background-color: var(--dropzone-bg);
	border-radius: var(--dropzone-border-radius);
	color: var(--text-color-dropzone);
}

.FileUploadCameraButton {
	margin-top: 10px;
}