:root {
	/* Dark theme colors */
	--background-color: #1e1e1e;
	--panel-background-color: #2d2d2d;
	--input-background-color: #2d2d2d;
	--text-color: #ffffff;
	--border-color: #444;
	--primary-color: #007bff;
	--primary-hover-color: #0056b3;
	--success-color: #28a745;
	--error-color: #dc3545;

	/* Spacing */
	--spacing-small: 10px;
	--spacing-medium: 15px;
	--spacing-large: 20px;

	/* Border radius */
	--border-radius: 8px;

	/* Shadows */
	--shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

	/* Button sizes */
	--button-width: 10em;
	--button-height: 50px;
}

/* Container for the entire component */
.DogChipScanner-container {
	background-color: var(--background-color);
	border-radius: var(--border-radius);
	width: 100%;
	box-shadow: var(--shadow);
	margin-bottom: var(--spacing-medium);
}

/* InputGroup Styling */
.DogChipScanner-inputGroup {
	display: flex;
	align-items: stretch;
	height: var(--button-height);
	width: 100%;
}

.DogChipScanner-input {
	flex-grow: 1;
	border-radius: var(--border-radius);
	font-size: 16px;
	border: 1px solid var(--border-color);
	background-color: var(--input-background-color);
	color: var(--text-color);
	transition: border-color 0.3s ease;
}

.DogChipScanner-input:focus {
	border-color: var(--primary-color);
	outline: none;
}

/* Buttons Styling */
.DogChipScanner-button {
	height: 100%;
	border-radius: var(--border-radius);
	font-size: 18px;
	border: 1px solid var(--border-color);
	background-color: var(--primary-color);
	color: var(--text-color);
	transition: background-color 0.3s ease, border-color 0.3s ease;
	margin-left: var(--spacing-small);
	width: var(--button-width);
	display: flex;
	align-items: center;
	justify-content: center;
}

.DogChipScanner-button:hover {
	background-color: var(--primary-hover-color);
	border-color: var(--primary-hover-color);
}

/* Mobile-specific styles */
@media (max-width: 768px) {
	.DogChipScanner-button {
		width: auto;
		padding: 0 var(--spacing-small);
	}

	.DogChipScanner-button span {
		display: none;
	}

	.DogChipScanner-button i {
		margin: 0;
	}
}

/* Panel Styling */
.DogChipScanner-panel {
	border: 1px solid var(--border-color);
	border-radius: var(--border-radius);
	background-color: var(--panel-background-color);
	box-shadow: var(--shadow);
	transition: max-height 0.3s ease, opacity 0.3s ease;
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	margin-top: var(--spacing-small);
}

.DogChipScanner-panel.open {
	max-height: 10000px;
	opacity: 1;
}

.DogChipScanner-panel-content {
	font-size: 18px;
	color: var(--text-color);
	padding: var(--spacing-small);
}

.DogChipScanner-panel-buttons {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center;
    gap: var(--spacing-small);
    margin-top: var(--spacing-small);
}

.DogChipScanner-panel-buttons p {
    width: 100%; /* Ensures the paragraph spans the full row */
    text-align: center; /* Center align text */
    margin-bottom: var(--spacing-small);
}

.DogChipScanner-panel-buttons div {
    display: flex;
    gap: var(--spacing-small);
    justify-content: center;
    width: 100%; /* Ensures the button container spans full width */
}


.DogChipScanner-panel-button {
	flex: 1;
	font-size: 18px;
	border: 1px solid var(--border-color);
	background-color: var(--primary-color);
	color: var(--text-color);
	transition: background-color 0.3s ease, border-color 0.3s ease;
}

.DogChipScanner-panel-button:hover {
	background-color: var(--primary-hover-color);
	border-color: var(--primary-hover-color);
}

/* Status colors for input */
.DogChipScanner-input.border-success {
	border-color: var(--success-color);
}

.DogChipScanner-input.border-danger {
	border-color: var(--error-color);
}