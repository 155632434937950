.tabSheetHundeGalerie {
	display: flex;
	flex-direction: column;
	background-color: #888;
}

.galerieoverlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.33);
	display: none;
	pointer-events: none;
}

.galerieoverlay.active {
	display: block;
}

.galerieoverlay.edit-mode {
	pointer-events: auto; /* Enable overlay to receive mouse events in edit mode */
}


.galerieoverlay-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: rgb(7, 214, 7);
	font-size: 24px;
}





.galerieContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 10px;
	padding: 10px;
	overflow-y: auto;
	background-color: wheat;
	min-height: 100px;
	max-height: 300px; /* Adjust as needed */
}


.tabsheet-hunde-galerie-spinnerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
}

.tabsheet-hunde-galerie-spinner {
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-left-color: #09f;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}


.galerieContainer::-webkit-scrollbar {
	width: 16px;
}

.galerieContainer::-webkit-scrollbar-thumb {
	background-color: #888;
	border-radius: 8px;
}

.galerieContainer::-webkit-scrollbar-track {
	background-color: #ddd;
}



.galerieImageWrapper {
	position: relative;
	width: auto;
	height: 200px;
}

/* Display galerieoverlay when hovering over galerieImageWrapper, excluding when selected */
.galerieImageWrapper:hover:not(.selected) .galerieoverlay {
	display: block;
}

/* Display galerieoverlay when in edit mode and selected, with higher z-index */
.galerieImageWrapper.selected .galerieoverlay {
	display: block;
	z-index: 1000;
}

.galerieImageWrapper.edit-mode > .image-container {
	pointer-events: none;
}

.galerieImageWrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.galerieImageWrapper:hover img {
	filter: brightness(0.8);
}



.galeriebuttonContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 10px;
}

.galeriebuttonContainer > Button {
	flex: 1;
	margin: 5px;
}  