.stammbuch-container {
    width: 100%;
    height: 100%;
    padding: 0%;
    overflow: hidden;
  }
  
  .table-container {
    width: 100%;
    height: calc(100% - 50px); /* Adjust height as needed */
    overflow: auto;
  }
  
  .custom-table {
    width: 100%;
  }
  
  /* Add other custom styles for the table as needed */  