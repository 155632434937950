/* Apply dark theme by default */
:root {
	--background-color: #1e1e1e;
	--text-color: #ffffff;
	--header-bg: #282c34;
	--header-text: #ffffff;
}

/* Ensure the app takes full height and has no overflow */
html, body, #root, .App {
	height: 100%;
	margin: 0;
	padding: 0;
	overflow: hidden; /* Prevent unwanted scrollbars */
	background-color: var(--background-color);
	color: var(--text-color);
}

.App {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.App-header {
	background-color: var(--header-bg);
	color: var(--header-text);
	min-height: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	padding: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
}

/* Main content area */
.main-content {
	flex: 1;
	overflow-y: auto; /* Allow scrolling only if content overflows */
	background-color: var(--background-color);
}

/* Remove default margin and padding from all elements */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* Optional: Add a transition for smooth theme changes */
body {
	transition: background-color 0.3s ease, color 0.3s ease;
}