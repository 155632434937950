:root {
	--background-color: #1e1e1e;
	--text-color: #ffffff;
	--input-background: #333333;
	--input-border: #555555;
	--input-focus-border: #777777;
	--grid-gap: 10px;
	--padding: 15px;
	--accordion-header-bg: #2a2a2a;
	--accordion-header-hover-bg: #3a3a3a;
	--button-bg: #444444;
	--button-hover-bg: #555555;
	--font-size-pc: 16px;
	--font-size-mobile: 14px;
	--tab-bg: #2a2a2a; /* Background color for tabs */
	--tab-active-bg: #1e1e1e; /* Background color for active tab */
	--tab-border-color: #444; /* Border color for tabs */
	--tab-text-color: #ffffff; /* Text color for tabs */
	--tab-active-text-color: #007bff; /* Text color for active tab */
	--tab-hover-bg: #3a3a3a; /* Background color on hover */
}

/* Override Bootstrap accordion variables for hund-profil-panel */
.accordion {
	--bs-accordion-border-width: 0px !important;
	--bs-accordion-bg:var(--tab-bg);
}

/* Optional: Add additional styles for hund-profil-panel */
.accordion {
	--bs-accordion-border-color: transparent; /* Remove border color */
	--bs-accordion-border-radius: 0; /* Remove border radius if needed */
}


/* Override Bootstrap Tabs styles */
.hund-profile-panel .nav-tabs {
	border-bottom: 1px solid var(--tab-border-color);
	padding: 0; /* Remove default padding */
	margin: 0; /* Remove default margin */
	display: flex; /* Ensure tabs are in a single row */
	gap: 0; /* Remove gap between tabs */
}

.hund-profile-panel .nav-tabs .nav-item {
	margin: 0; /* Remove margin between tab items */
}

.hund-profile-panel .nav-tabs .nav-link {
	background-color: var(--tab-bg);
	border: 1px solid var(--tab-border-color);
	border-bottom: none;
	color: var(--tab-text-color);
	padding: 10px 20px;
	margin-right: 0; /* Remove margin between tabs */
	border-radius: 4px 4px 0 0;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.hund-profile-panel .nav-tabs .nav-link:hover {
	background-color: var(--tab-hover-bg);
	border-color: var(--tab-border-color);
}

.hund-profile-panel .nav-tabs .nav-link.active {
	background-color: var(--tab-active-bg);
	border-color: var(--tab-border-color);
	border-bottom-color: transparent;
	color: var(--tab-active-text-color);
}

.hund-profile-panel .tab-content {
	background-color: var(--tab-active-bg);
	border: 1px solid var(--tab-border-color);
	border-top: none;
	padding: 15px;
	border-radius: 0 0 4px 4px;
}

.hund-profile-panel {
	background-color: var(--background-color);
	color: var(--text-color);
	border: 1px solid var(--input-border);
	border-radius: 8px;
	margin-bottom: var(--grid-gap);
	overflow: hidden;
}

.hund-profile-panel .accordion-header {
	background-color: var(--accordion-header-bg);
	padding: var(--padding);
	cursor: pointer;
	transition: background-color 0.3s ease;
}

.hund-profile-panel .accordion-header:hover {
	background-color: var(--accordion-header-hover-bg);
}

.hund-profile-panel .accordion-button {
	background-color: transparent;
	color: var(--text-color);
	border: none;
	box-shadow: none;
}

.hund-profile-panel .header-grid-container {
	display: grid;
	grid-template-columns: 120px 1fr;
	gap: var(--grid-gap);
	align-items: center;
	width: 100%;
}

.hund-profile-panel .header-grid-cell-profilbild {
	grid-column: 1 / 2;
}

.hund-profile-panel .header-grid-cell-profilbild img {
	width: 100%;
	height: auto;
	border-radius: 8px;
}

.hund-profile-panel .header-grid-cell-data {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: var(--grid-gap);
	font-size: var(--font-size-pc);
}

.hund-profile-panel .header-grid-cell-data .data-item {
	display: flex;
	align-items: center;
	gap: 8px;
}

.hund-profile-panel .header-grid-cell-data .data-item span {
	font-weight: bold;
	color: #cccccc;
}

.hund-profile-panel .footer-area {
	display: flex;
	justify-content: flex-end;
	gap: var(--grid-gap);
	padding: var(--padding);
	background-color: var(--accordion-header-bg);
}

.hund-profile-panel .footer-area button {
	background-color: var(--button-bg);
	border: none;
	color: var(--text-color);
	padding: 8px 16px;
	border-radius: 4px;
	transition: background-color 0.3s ease;
}

.hund-profile-panel .footer-area button:hover {
	background-color: var(--button-hover-bg);
}

/* Mobile layout (portrait) */
@media screen and (max-width: 768px) and (orientation: portrait) {
	.hund-profile-panel .header-grid-container {
		grid-template-columns: 1fr;
		gap: var(--grid-gap);
	}

	.hund-profile-panel .header-grid-cell-profilbild {
		grid-column: 1 / -1;
		text-align: center;
	}

	.hund-profile-panel .header-grid-cell-data {
		grid-template-columns: 1fr;
		font-size: var(--font-size-mobile);
	}
}

/* Mobile layout (landscape) */
@media screen and (max-width: 768px) and (orientation: landscape) {
	.hund-profile-panel .header-grid-container {
		grid-template-columns: 100px 1fr;
		gap: var(--grid-gap);
	}

	.hund-profile-panel .header-grid-cell-data {
		grid-template-columns: repeat(2, 1fr);
		font-size: var(--font-size-mobile);
	}
}