/* Ensure the container takes up the full height of the viewport */
.NavigationPage-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start; /* Align content to the top */
	min-height: 100%; /* Full viewport height */
	background-color: #121212; /* Dark theme background */
	color: white;
	padding: 20px;
	box-sizing: border-box;
  }
  
  /* Style for the title */
  .NavigationPage-title {
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 20px;
  }
  
  /* Container for the buttons */
  .NavigationPage-buttons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 15px; /* Space between buttons */
	width: 100%;
	max-width: 800px; /* Optional: Limit the maximum width */
  }
  
  /* Style for the buttons */
  .NavigationPage-button {
	width: calc(50% - 10px); /* Two buttons per row with gap */
	height: 70px;
	font-size: 20px;
	font-weight: bold;
	border-radius: 8px;
	background-color: #007bff;
	color: white;
	border: 2px solid #ffffff;
	cursor: pointer;
	transition: background-color 0.3s, border-color 0.3s;
  }
  
  /* Hover effect for buttons */
  .NavigationPage-button:hover {
	background-color: #0056b3;
	border-color: #003f7f;
  }