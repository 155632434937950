.image-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.broken-image-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  color: red; /* or any other color */
}

.broken-image-label i {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.circular .broken-image-label i {
  font-size: min(50%, 100%); /* Adjust icon size */
}

.modal-image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  margin: 0 auto;
}

.circular .image {
  border-radius: 50%;
  width: 150px;
  padding: 5px;
  border-color: black;
  border-width: 3px;
  border-style: solid;
}

.image-input-file {
  width : 100%;
}

.image-webcam-button {
  width : 100%;
}

.webcam-container {
  width: 100%;
}

.webcam-capture-buttongroup{
  display: flex;
  width: 100%;
}

.webcam-capture-buttongroup > Button { 
  flex:2;
  align-items: center;
}