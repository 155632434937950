:root {
	--background-color: #1e1e1e;
	--text-color: #ffffff;
	--input-background: #333333;
	--input-border: #555555;
	--input-focus-border: #777777;
	--grid-gap: 10px;
	--padding: 15px;
}

.tabsheet-hunde-profil-body-grid-container {
	display: grid;
	grid-template-areas:
		"istruede	wurfnummer		stammhund	status"
		"profilbild	chipnummer		rasse		zuchtbuchnummer"
		"profilbild	hundname		farbe		kennelname"
		"profilbild	geburtsdatum	trimfarbe	lastta"
		"profilbild	augenfarbe		statur		nextta"
		"profilbild	.				rute		.	"
		"profilbild	mutterId 		.			registrierteInstanz"
		"profilbild	vaterId			.			registrierterName"
		"profilbild	besitzerId		.			impfungen";
	gap: var(--grid-gap);
	padding: var(--padding);
	background-color: var(--background-color);
	color: var(--text-color);
}

.tabsheet-hunde-profil-body-grid-container > div {
	display: flex;
	align-items: center;
}

.tabsheet-hunde-profil-body-grid-container > div > label {
	flex: 1;
	text-align: right;
	margin-right: 10px;
	white-space: nowrap; /* Prevent label text from wrapping */
	min-width: 0; /* Allow labels to shrink */
}

.tabsheet-hunde-profil-body-grid-container > div > input,
.tabsheet-hunde-profil-body-grid-container > div > select,
.tabsheet-hunde-profil-body-grid-container > div > textarea {
	flex: 2;
	width: 100%;
	padding: 5px;
	background-color: var(--input-background);
	border: 1px solid var(--input-border);
	color: var(--text-color);
}

.tabsheet-hunde-profil-body-grid-container > div > div > div> select {
	flex: 2;
	width: 100%;
	padding: 5px;
	background-color: var(--input-background);
	border: 1px solid var(--input-border);
	color: var(--text-color);
}

.tabsheet-hunde-profil-body-grid-container > div > input:focus,
.tabsheet-hunde-profil-body-grid-container > div > select:focus,
.tabsheet-hunde-profil-body-grid-container > div > div > div > select:focus,
.tabsheet-hunde-profil-body-grid-container > div > textarea:focus {
	border-color: var(--input-focus-border);
	outline: none;
}

.tabsheet-hunde-profil-grid-cell-profilbild {
	grid-area: profilbild;
	display: flex;
	flex-direction: column;
	align-items: center; /* Center the image horizontally */
	justify-content: center; /* Center the image vertically */
	gap: var(--grid-gap);
	height: auto;
	max-width: 300px; /* Limit the size of the profilbild */
}

.tabsheet-hunde-profil-profilbild {
	width: 100%;
	height: auto;
	border-radius: 8px;
}


.tabsheet-hunde-profil-grid-cell-augenfarbe {
	grid-area: augenfarbe;
	display: flex;
	align-items: center;
	gap: var(--grid-gap);
}

.tabsheet-hunde-profil-grid-cell-augenfarbe > div {
	flex: 1;
	display: flex;
	align-items: center;
	gap: var(--grid-gap);
}

.tabsheet-hunde-profil-grid-cell-augenfarbe label {
	flex: 1; /* Allow the label to shrink */
	text-align: right;
	margin-right: 10px;
	white-space: nowrap; /* Prevent label text from wrapping */
	min-width: 0; /* Allow labels to shrink */
}

.tabsheet-hunde-profil-grid-cell-stammhund {
	grid-area: stammhund;
	display: flex;
	align-items: center;
}

.tabsheet-hunde-profil-grid-cell-augenfarbe .dropdown-container {
	flex: 2; /* Ensure dropdowns take up the remaining space */
	width: 100%;
}

.tabsheet-hunde-profil-grid-cell-stammhund label {
	margin-right: 10px;
}

.tabsheet-hunde-profil-grid-cell-stammhund input[type="checkbox"] {
	width: auto;
	margin-left: 10px;
}


.tabsheet-hunde-profil-form-switch {
	display: flex;
	align-items: center;
	width: 100%;
	padding-left: 0em;
}

.tabsheet-hunde-profil-form-switch > label {
	flex: 2;
	text-align: right;
}

.tabsheet-hunde-profil-form-switch > div {
	flex: 2;
}

.tabsheet-hunde-profil-form-check-input {
	height: 30px;
	width: 60px !important;
	margin-right: 1em;
}

/* Style the Mars (male) icon */
.gender-icon.male {
	color: blue;
	text-shadow: 0 0 5px rgba(0, 0, 255, 0.5); /* Blue glow */
}

/* Style the Venus (female) icon */
.gender-icon.female {
	color: pink;
	text-shadow: 0 0 5px rgba(255, 0, 255, 0.5); /* Pink glow */
}

.tabsheet-hunde-profil-grid-cell-mutterId,
.tabsheet-hunde-profil-grid-cell-vaterId {
	display: flex;
	align-items: center;
	gap: var(--grid-gap);
}

.tabsheet-hunde-profil-grid-cell-mutterId > div,
.tabsheet-hunde-profil-grid-cell-vaterId > div {
	flex: 1;
}

.tabsheet-hunde-profil-grid-cell-hundname {
	grid-area: hundname;
}

.tabsheet-hunde-profil-grid-cell-kennelname {
	grid-area: kennelname;
}

.tabsheet-hunde-profil-grid-cell-geburtsdatum {
	grid-area: geburtsdatum;
}

.tabsheet-hunde-profil-grid-cell-chipnummer {
	grid-area: chipnummer;
}

.tabsheet-hunde-profil-grid-cell-farbe {
	grid-area: farbe;
}

.tabsheet-hunde-profil-grid-cell-statur {
	grid-area: statur;
}

.tabsheet-hunde-profil-grid-cell-augenfarbe {
	grid-area: augenfarbe;
}

.tabsheet-hunde-profil-grid-cell-mutterId {
	grid-area: mutterId;
}

.tabsheet-hunde-profil-grid-cell-vaterId {
	grid-area: vaterId;
}

.tabsheet-hunde-profil-grid-cell-besitzerId {
	grid-area: besitzerId;
}

.tabsheet-hunde-profil-grid-cell-istruede {
	grid-area: istruede;
}

.tabsheet-hunde-profil-grid-cell-wurfnummer {
	grid-area: wurfnummer;
}

.tabsheet-hunde-profil-grid-cell-stammhund {
	grid-area: stammhund;
}

.tabsheet-hunde-profil-grid-cell-registrierterName {
	grid-area: registrierterName;
}

.tabsheet-hunde-profil-grid-cell-zuchtbuchnummer {
	grid-area: zuchtbuchnummer;
}

.tabsheet-hunde-profil-grid-cell-status {
	grid-area: status;
}

.tabsheet-hunde-profil-grid-cell-rasse {
	grid-area: rasse;
}

.tabsheet-hunde-profil-grid-cell-registrierteInstanz {
	grid-area: registrierteInstanz;
}

.tabsheet-hunde-profil-grid-cell-trimfarbe {
	grid-area: trimfarbe;
}

.tabsheet-hunde-profil-grid-cell-rute {
	grid-area: rute;
}

.tabsheet-hunde-profil-grid-cell-impfungen {
	grid-area: impfungen;
}

.tabsheet-hunde-profil-grid-cell-lastta {
	grid-area: lastta;
}

.tabsheet-hunde-profil-grid-cell-nextta {
	grid-area: nextta;
}

/* Mobile layout */
@media screen and (max-width: 768px) {
	.tabsheet-hunde-profil-body-grid-container {
		grid-template-areas:
			"profilbild"
			"istruede"
			"hundname"
			"chipnummer"
			"geburtsdatum"
			"mutterId"
			"vaterId"
			"besitzerId"
			"farbe"
			"trimfarbe"
			"statur"
			"rute"
			"augenfarbe"
			"wurfnummer"
			"status"
			"kennelname"
			"rasse"
			"zuchtbuchnummer"
			"registrierteInstanz"
			"stammhund"
			"registrierterName"
			"lastta"
			"nextta"
			"impfungen";
		padding: 10px;
	}

	.tabsheet-hunde-profil-body-grid-container > div {
		flex-direction: column;
		align-items: stretch;
	}

	.tabsheet-hunde-profil-body-grid-container > div > input,
	.tabsheet-hunde-profil-body-grid-container > div > select {
		width: 100%;
	}

	.tabsheet-hunde-profil-body-grid-container > div > label {
		text-align: left;
		margin-right: 0;
		margin-bottom: 5px;
	}

	.tabsheet-hunde-profil-grid-cell-profilbild {
		max-width: 100%; /* Allow the image to take full width on mobile */
	}
}