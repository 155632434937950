.qrScannerPanel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure the panel is displayed on top of other content */
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
}

.closeButton {
    position: absolute;
    top: 20px; /* Adjust the top position as needed */
    right: 20px; /* Adjust the right position as needed */
    z-index: 1100; /* Ensure the button is displayed on top of the overlay */
    background-color: transparent;
    color: white;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.panel {
    position: relative;
    width: 640px; /* Adjust width as needed */
    height: 480px; /* Adjust height as needed */
    margin: auto; /* Center horizontally */
    z-index: 1100; /* Ensure the panel is displayed on top of the overlay */
}

