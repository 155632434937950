/* Make the icon larger */
.gender-icon {
    font-size: 36px;
}

.form-switch {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 0em;
}

.form-switch > label {
    flex: 2;
    text-align: right;
}
  
.form-switch > div {
    flex: 2;
}

.form-check-input {
    height: 30px;
    width: 60px !important;
    margin-right: 1em;
}
  
/* Style the Mars (male) icon */
.gender-icon.male {
    color: blue;
    text-shadow: 0 0 5px rgba(0, 0, 255, 0.5); /* Blue glow */
}
  
/* Style the Venus (female) icon */
.gender-icon.female {
    color: pink;
    text-shadow: 0 0 5px rgba(255, 0, 255, 0.5); /* Pink glow */
}