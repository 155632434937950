:root {
	--background-color: #1e1e1e;
	--text-color: #ffffff;
	--input-background: #333333;
	--input-border: #555555;
	--input-focus-border: #777777;
	--grid-gap: 10px;
	--padding: 15px;
	--button-bg: #007bff;
	--button-hover-bg: #0056b3;
	--button-disabled-bg: #cccccc;
	--button-disabled-text: #666666;
	--error-color: #dc3545;
	--success-color: #28a745;
}

.Kaufvertrag-container {
	padding: var(--padding);
	background-color: var(--background-color);
	color: var(--text-color);
	border-radius: 8px;
	width: 80%;
	margin: auto;
	min-height: 100%;
	display: flex;
	flex-direction: column;
}

.Kaufvertrag-form {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: var(--grid-gap);
}

.Kaufvertrag-form-group {
	display: grid;
	grid-template-columns: 1fr;
	gap: var(--grid-gap);
	width: 100%;
}

.Kaufvertrag-row {
	display: flex;
	flex-wrap: wrap;
	gap: var(--grid-gap);
	align-items: center;
}

.Kaufvertrag-button-row {
	display: flex;
	flex-wrap: wrap;
	gap: var(--grid-gap);
	align-items: center;
	width: 100%;
}

.Kaufvertrag-row label {
	flex: 0.3;
	text-align: right;
	white-space: nowrap;
	min-width: 0;
}

.Kaufvertrag-row input,
.Kaufvertrag-row select,
.Kaufvertrag-form-group select,
.Kaufvertrag-row textarea {
	flex: 2;
	padding: 10px;
	border-radius: 4px;
	border: 1px solid var(--input-border);
	background-color: var(--input-background);
	color: var(--text-color);
}

.Kaufvertrag-row input:focus,
.Kaufvertrag-row select:focus,
.Kaufvertrag-form-group select:focus,
.Kaufvertrag-row textarea:focus {
	border-color: var(--input-focus-border);
	outline: none;
}

.Kaufvertrag-row textarea {
	height: 100px;
}

.Kaufvertrag-button-row button {
	flex: 1;
	padding: 10px 20px;
	border: none;
	border-radius: 4px;
	background-color: var(--button-bg);
	color: var(--text-color);
	cursor: pointer;
	font-size: 16px;
}

.Kaufvertrag-button-row button:hover {
	background-color: var(--button-hover-bg);
}

.Kaufvertrag-button-row button:disabled {
	background-color: var(--button-disabled-bg);
	color: var(--button-disabled-text);
	cursor: not-allowed;
	opacity: 0.6;
}

.Kaufvertrag-verification-page,
.Kaufvertrag-pdf-preview-container,
.Kaufvertrag-pdf-preview,
.Kaufvertrag-submission-page {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--grid-gap);
	width: 100%;
}

.Kaufvertrag-verification-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: var(--grid-gap);
	width: 100%;
}

.Kaufvertrag-verification-item span {
	flex: 1;
	text-align: right;
}

.Kaufvertrag-verification-item .Kaufvertrag-success-icon,
.Kaufvertrag-verification-item .Kaufvertrag-error-icon,
.Kaufvertrag-verification-item .Kaufvertrag-loading-icon {
	flex: 1;
	text-align: left;
}

.Kaufvertrag-success-icon {
	color: var(--success-color);
	font-size: 24px;
}

.Kaufvertrag-error-icon {
	color: var(--error-color);
	font-size: 24px;
}

.Kaufvertrag-loading-icon {
	font-size: 24px;
}

.Kaufvertrag-pdf-preview-container iframe {
	width: 100%;
	height: 800px;
	border: none;
}

.Kaufvertrag-submission-page {
	text-align: center;
}

.Kaufvertrag-submission-page .Kaufvertrag-row {
	justify-content: center;
}

/* Mobile layout */
@media screen and (max-width: 768px) {
	.Kaufvertrag-container {
		width: 100%;
		padding: 10px;
	}

	.Kaufvertrag-form-group {
		grid-template-columns: 1fr;
	}

	.Kaufvertrag-row {
		flex-direction: column;
		align-items: stretch;
	}

	.Kaufvertrag-row label {
		text-align: left;
	}

	.Kaufvertrag-row input,
	.Kaufvertrag-row select,
	.Kaufvertrag-form-group select,
	.Kaufvertrag-row textarea {
		width: 100%;
	}

	.Kaufvertrag-button-row button {
		width: 100%;
	}
}